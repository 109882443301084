<template>
  <section>
    <InlineMessageContainer v-if="showSetUpContainer" text="You are not getting paid.">
      <template slot="custom">
        <router-link class="link mr-1 ml-1" :to="{ name: 'affiliates-set-up-stripe' }"
          > Set up your </router-link
        >
        stripe account to receive payments
      </template>
    </InlineMessageContainer>
  </section>
</template>

<script>
import InlineMessageContainer from "../components/InlineMessageContainer.vue";
import { mapState } from "vuex";
import { UsersStates } from "../store/Storetypes";
export default {
  components: {
    InlineMessageContainer,
  },
  computed: {
    ...mapState({
      user: (state) => state.users[UsersStates.USER],
    }),
    showSetUpContainer() {
      return !this.user.isStripeAccountSetup;
    },
  },
};
</script>
